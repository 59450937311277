<template>
  <div class="mt-2 titleContainer">
    <div class="titleForm rounded">
      <svg class="bi close" fill="currentColor" @click="rollbackLink">
        <use xlink:href="/img/icons/bootstrap-icons.svg#x" />
      </svg>
      <p class="text-center pt-4 pb-2 px-2 border">
        Usted se encuentra: {{ title }}
        <strong class="text-capitalize">{{ strong }}</strong>
      </p>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  props: ["title", "strong"],
  methods: {
    rollbackLink() {
      Swal.fire({
        title: "¿Abandonar el Formulario?",
        showCancelButton: true,
        confirmButtonText: `Abandonar`,
        cancelButtonText: "Quedarse"
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.go(-1);
        }
      });
    }
  },
  name: "title form"
};
</script>

<style lang="css" scoped>
.titleContainer {
  width: 100vw;
}
.titleForm {
  background-color: var(--secondary-bg);
  width: 350px;
  max-width: 100%;
  margin: auto;
  position: relative;
}
.close {
  position: absolute;
  height: 30px;
  width: 30px;
  right: 5px;
  cursor: pointer;
}
</style>

<template>
  <div class="d-flex justify-content-center passContainer">
    <h4
      v-for="(section, index) in sections"
      :key="index"
      class="sectionHeaderForm"
      v-bind:class="{ passSelect: isActive(section) }"
      @click.prevent="move(index)"
    >
      {{ index + 1 }}
    </h4>
  </div>
</template>

<script>
export default {
  props: ["sections", "activeSection"],
  methods: {
    isActive(section) {
      if (section === this.activeSection) {
        return true;
      }
      return false;
    },
    move(position) {
      this.$emit("moveForm", position);
    }
  },
  name: "passContainer"
};
</script>

<style lang="css" scoped>
.passContainer {
  width: 100vw;
}
.passContainer::after {
  content: "";
  width: 100%;
  border-bottom: solid 2px var(--main-text);
  position: absolute;
  left: 0;
  top: 45px;
  z-index: -1;
}
.passSelect {
  color: white !important;
  background-color: var(--bs-primary) !important;
  height: 50px !important;
  width: 50px !important;
  border-color: var(--bs-primary) !important;
  cursor: default !important;
}
.sectionHeaderForm {
  background-color: var(--main-bg);
  border: 2px solid var(--main-text);
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
</style>

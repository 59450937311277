<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="employeeList" class="form-label">* Empleado Afectado</label>
        <select
          name="employeeList"
          class="form-select"
          v-model="employee"
          required
        >
          <option
            v-for="employee in employeeList"
            v-bind:value="employee._id"
            :key="employee._id"
            >{{ employee.name }}</option
          >
        </select>
      </div>
      <div class="mb-3">
        <label for="diseaseDate" class="form-label"
          >* Fecha de Estructuración</label
        >
        <input
          type="date"
          class="form-control"
          name="diseaseDate"
          v-model="diseaseDate"
          required
        />
      </div>
      <div class="mb-3">
        <label for="daysOfDisability" class="form-label"
          >* Días de Incapacidad</label
        >
        <input
          type="number"
          class="form-control"
          name="daysOfDisability"
          v-model="daysOfDisability"
          max="999"
          min="0"
          required
        />
      </div>
      <div class="mb-3">
        <label for="PCL" class="form-label"
          >* Procentaje de Pérdida de Capacidad Laboral (PCL)</label
        >
        <input
          type="number"
          class="form-control"
          name="PCL"
          v-model="PCL"
          max="100"
          min="0"
          step=".01"
          required
        />
      </div>
      <div class="d-flex justify-content-end">
        <button
          @click.prevent="next('forward')"
          class="btn btn-secondary shadow-secondary"
        >
          Siguiente
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "defaultData", "submitName"],
  data() {
    return {
      initDefault: true,
      mounted: false,
      employeeList: [],
      employee: "",
      diseaseDate: "",
      daysOfDisability: "",
      PCL: ""
    };
  },
  mounted() {
    store.getData("employee").then(employeeList => {
      employeeList.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      employeeList.forEach(employee => {
        if (employee.branch === this.$route.params._id) {
          this.employeeList.push(employee);
        }
      });
    });
    this.mounted = true;
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.employee !== undefined && val.employee !== null) {
              this.employee = val.employee;
            }
            if (val.diseaseDate !== undefined && val.diseaseDate !== null) {
              const d = new Date(val.diseaseDate);
              const year = d.getFullYear();
              let month = d.getMonth() + 1;
              if (month < 10) {
                month = "0" + month.toString();
              }
              let day = d.getDate();
              if (day < 10) {
                day = "0" + day.toString();
              }
              this.diseaseDate = year + "-" + month + "-" + day;
            }
            if (
              val.daysOfDisability !== undefined &&
              val.daysOfDisability !== null
            ) {
              this.daysOfDisability = val.daysOfDisability;
            }
            if (val.PCL !== undefined && val.PCL !== null) {
              this.PCL = val.PCL;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Employee EL Form 1"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>

<template>
  <div>
    <passContainer
      :sections="sections"
      :activeSection="activeSection"
      @moveForm="moveForm($event)"
    ></passContainer>
    <titleForm
      title="Registrando una enfermedad laboral en la sede: "
      :strong="branchName"
    ></titleForm>
    <form
      id="EmployeeForm"
      class="mainForm mb-3 needs-validation"
      v-bind:class="{ 'was-validated': showValidate }"
    >
      <div class="xScroll d-flex">
        <first
          :submitValidate="submitStart"
          :sectionName="sections[0]"
          sectionPosition="0"
          @moveForm="moveForm($event)"
        ></first>
        <second
          :submitValidate="submitStart"
          :sectionName="sections[1]"
          sectionPosition="1"
          @moveForm="moveForm($event)"
          submitName="Registrar"
          @submitForm="submit()"
        ></second>
      </div>
    </form>
    <Legal></Legal>
  </div>
</template>

<style lang="css" scoped>
.xScroll {
  margin-left: 0%;
  transition: 0.3s ease-in-out;
}
.mainForm {
  z-index: 8;
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
}
</style>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";
import passContainer from "@/components/form/passContainer";
import titleForm from "@/components/form/Title";

import { validateFormData } from "@/modules/tools";

import first from "@/views/employee/form/Disease/1";
import second from "@/views/employee/form/Disease/2";

export default {
  name: "Employee Evaluation Create",
  data() {
    return {
      sections: ["Datos Básicos", "Enfermedad"],
      activeSection: "",
      submitStart: false,
      showValidate: false,
      branchName: ""
    };
  },
  components: {
    Legal,
    passContainer,
    titleForm,
    first,
    second
  },
  created() {
    this.activeSection = this.sections[0];
    const store = new Storage();
    store.getData("branch", this.$route.params._id).then(branch => {
      store.getData("city", branch.city).then(city => {
        this.branchName = city.name;
      });
    });
  },
  methods: {
    submit() {
      const EmployeeForm = document.getElementById("EmployeeForm");
      const input = EmployeeForm.getElementsByTagName("input");
      const select = EmployeeForm.getElementsByTagName("select");
      const textarea = EmployeeForm.getElementsByTagName("textarea");
      let submit = true;

      this.submitStart = !this.submitStart;
      this.showValidate = true;

      for (let i = 0; i < input.length; i++) {
        if (!validateFormData(input[i])) {
          submit = false;
        }
      }
      for (let i = 0; i < select.length; i++) {
        if (!validateFormData(select[i])) {
          submit = false;
        }
      }
      for (let i = 0; i < textarea.length; i++) {
        if (!validateFormData(textarea[i])) {
          submit = false;
        }
      }

      if (submit) {
        this.sendData(EmployeeForm);
      }
    },
    sendData(form) {
      const store = new Storage();
      const formData = new FormData(form);

      Swal.fire({
        icon: "warning",
        title: "Registrando enfermedad laboral",
        html:
          "Por favor espere mientras se registra la enfermedad laboral</b> " +
          '<div class="progress mt-3"><div class="progress-bar" id="progressDataForm" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      const data = {
        employee: formData.get("employeeList"),
        description: formData.get("description"),
        diseaseDate: new Date(formData.get("diseaseDate")).getTime(),
        causedDeath: formData.get("causedDeath") == "true",
        daysOfDisability: formData.get("daysOfDisability"),
        PCL: formData.get("PCL"),
        branch: this.$route.params._id,
        company: this.companyId
      };

      store
        .insertData("optima", "disease", [data])
        .then(() => {
          this.endSubmit(true);
        })
        .catch(error => {
          console.log(error);
          this.endSubmit(false);
        });
    },
    endSubmit(status) {
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Enfermedad Laboral registrada",
          text: "Todos los datos han sido sincronizados"
        }).then(result => {
          this.$router.go(-1);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al registrar la enfermedad laboral",
          text:
            "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
        }).then(result => {
          this.$router.go(-1);
        });
      }
    },
    moveForm(event) {
      const xScroll = document.getElementsByClassName("xScroll")[0];
      let postion;

      if (event === "forward") {
        for (let i = 0; i < this.sections.length; i++) {
          if (this.activeSection === this.sections[i]) {
            postion = i + 1;
            break;
          }
        }
      } else if (event === "back") {
        for (let i = 0; i < this.sections.length; i++) {
          if (this.activeSection === this.sections[i]) {
            postion = i - 1;
            break;
          }
        }
      } else {
        postion = event;
      }
      this.activeSection = this.sections[postion];
      xScroll.style.marginLeft = postion * 100 * -1 + "%";
    }
  }
};
</script>
